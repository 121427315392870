.pic-box {
  width: 450px;
  height: auto;
  border: 2px solid;
  border-radius: 5px;
  margin: 5px;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.pic-box img {
  height: 300px;
}

@media screen and (max-width: 600px) and (orientation: portrait) {
  .pic-box {
    max-width: 90vw;
  }

  .pic-box img {
    height: 60vw;
  }
}
