.App {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.basic-view {
  min-height: 90vh;
  text-align: center;
  max-width: 95vw;
}
